import { outputTemplateMetadataFragment } from '@eluve/frontend-appointment-hooks';
import { graphql } from '@eluve/graphql.tada';

export const summaryContentFragment = graphql(
  `
    fragment SummaryContent on Appointments @_unmask {
      __typename
      id
      mode
      humanOutputs(
        where: { output: { outputType: { _eq: SOAP_NOTE } } }
        limit: 1
        orderBy: { output: { createdAt: DESC } }
      ) {
        __typename
        humanOutputId
        output {
          __typename
          id
          content
          userId
          llm_output {
            __typename
            id
            content
            metadata {
              ...OutputTemplateMetadata
            }
            modelPromptTemplate {
              __typename
              id
              template {
                __typename
                id
                name
                outputType
              }
            }
          }
        }
        output_template {
          __typename
          outputTemplateId
          variantNumber
          template
        }
      }
    }
  `,
  [outputTemplateMetadataFragment],
);

export const draftContentFragment = graphql(
  `
    fragment DraftContent on Appointments @_unmask {
      __typename
      id
      doctor_interaction {
        __typename
        appointmentId
        noteSignedAt
        additionalNotes
        externalChartUrl
        externalEhrSyncStatus
      }
      ...SummaryContent
    }
  `,
  [summaryContentFragment],
);

export const ehrDiffContentFragment = graphql(
  `
    fragment EhrDiffContent on Appointments @_unmask {
      __typename
      id
      external_charts {
        __typename
        id
        rawData
        external_ehr {
          __typename
          id
          vendor
          domain
        }
      }
      ...DraftContent
    }
  `,
  [draftContentFragment],
);
