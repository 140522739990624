import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { eluveAdminHasuraContext } from '@eluve/apollo-client';
import { DbEnums } from '@eluve/graphql-types';
import {
  DynamicArtifactTemplate,
  DynamicSummary,
  LegacySOAPOutputTemplate,
  dynamicArtifactTemplateSchema,
  hydrateDynamicArtifactTemplate,
} from '@eluve/llm-outputs';
import { useIsEluveAdmin } from '@eluve/session-helpers';

import { useAppointmentContext } from './appointment.context';
import {
  getCurrentSummary,
  getCurrentSummaryForEluveAdmin,
} from './operations';
import { useAppointmentMode } from './useAppointmentMode';

type SummaryReturn = {
  summary: DynamicSummary | null;
  /**
   * Indicates that there is a summary available for the
   * appointment that has been parsed correctly;
   *
   * If this is false, the summary is null;
   * If this is true, the summary is not null;
   */
  isSummaryAvailable: boolean;
  llmOutputId: string | null;
  humanOutputId: string | null;
  refetch: () => void;
  /**
   * Indicates that there is no relevant summary data available.
   * In the case of manual charting, this means that the user
   * has not yet written anything in the chart
   */
  isEmpty: boolean;
};

/**
 * Returns the current summary for an appointment if it is available
 *
 */
export const useSummary = (): SummaryReturn => {
  const { tenantId, appointmentId } = useAppointmentContext();
  const appointmentMode = useAppointmentMode();

  const isEluveAdmin = useIsEluveAdmin();
  const summaryQuery = useMemo(
    () => (isEluveAdmin ? getCurrentSummaryForEluveAdmin : getCurrentSummary),
    [isEluveAdmin],
  );

  const { data, refetch } = useQuery(summaryQuery, {
    variables: {
      tenantId,
      appointmentId,
    },
    context: isEluveAdmin ? eluveAdminHasuraContext : undefined,
  });

  const currentSoapNote = data?.appointmentsByPk?.humanOutputs?.[0];
  const summary = currentSoapNote?.output?.content ?? null;
  const llmOutputMetadata = currentSoapNote?.output?.llm_output?.metadata;
  const llmOutputType = llmOutputMetadata?.prompt_template?.outputType;

  let outputTemplate: DynamicArtifactTemplate | null = null;

  // If the output type is SOAP_NOTE, we need to use the legacy template
  if (llmOutputType === DbEnums.LlmOutputType.SOAP_NOTE) {
    outputTemplate = LegacySOAPOutputTemplate;
  } else if (appointmentMode === DbEnums.AppointmentMode.MANUAL_CHARTING) {
    // If we started the appointment in manual charting mode, we need to use the
    // output template from `appointment_human_outputs`
    const outputTemplateRaw = currentSoapNote?.output_template?.template;
    if (outputTemplateRaw) {
      outputTemplate = dynamicArtifactTemplateSchema.parse(outputTemplateRaw);
    }
  } else if (appointmentMode === DbEnums.AppointmentMode.AUDIO_COPILOT) {
    // If this is an audio co-pilot appointment, we need to use the output template
    // from the LLM output metadata
    const outputTemplateRaw = llmOutputMetadata?.output_variant?.template;
    if (outputTemplateRaw) {
      outputTemplate = dynamicArtifactTemplateSchema.parse(outputTemplateRaw);
    }
  }

  if (!outputTemplate || !summary) {
    return {
      isSummaryAvailable: false,
      isEmpty: true,
      llmOutputId: null,
      humanOutputId: null,
      refetch,
      summary: null,
    };
  }

  const hydrated = hydrateDynamicArtifactTemplate(outputTemplate, summary);

  // If this is a manual chart and its updated at time is the same as its created at time
  // then we know the user has never interacted with it
  const isEmpty =
    appointmentMode === DbEnums.AppointmentMode.MANUAL_CHARTING
      ? currentSoapNote?.output?.updatedAt ===
        currentSoapNote?.output?.createdAt
      : false;

  return {
    humanOutputId: currentSoapNote?.humanOutputId ?? null,
    llmOutputId: currentSoapNote?.output?.editedFromLlmOutputId ?? null,
    isSummaryAvailable: true,
    isEmpty,
    refetch,
    summary: {
      type: 'DYNAMIC',
      blocks: hydrated.blocks,
    },
  };
};
