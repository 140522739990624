import { useCompleteFragment } from '@eluve/apollo-client';
import { DbEnums } from '@eluve/graphql-types';
import {
  DynamicArtifactTemplate,
  DynamicSummary,
  LegacySOAPOutputTemplate,
  dynamicArtifactTemplateSchema,
  hydrateDynamicArtifactTemplate,
} from '@eluve/llm-outputs';

import { summaryContentFragment } from './operations';

type SummaryReturn = {
  llmSummary: DynamicSummary | null;
  humanSummary: DynamicSummary | null;
  outputTemplate: DynamicArtifactTemplate | undefined;
};

/**
 * Returns the current summary for an appointment if it is available
 *
 * The parsing here should remain consistent with the parsing in the useSummary hook
 * in the appointment-hooks package.
 */
export const useSummary = ({
  appointmentId,
}: {
  appointmentId: string;
}): SummaryReturn => {
  const appointment = useCompleteFragment({
    fragment: summaryContentFragment,
    key: { id: appointmentId },
  });

  const appointmentMode = appointment?.mode;

  const humanOutput = appointment?.humanOutputs?.[0];
  const humanOutputContent = humanOutput?.output?.content;

  const llmOutput = humanOutput?.output?.llm_output;
  const llmOutputContent = llmOutput?.content;
  const llmOutputMetadata = llmOutput?.metadata;

  const llmOutputType = llmOutput?.modelPromptTemplate?.template?.outputType;

  let outputTemplate: DynamicArtifactTemplate | undefined = undefined;

  // If the output type is SOAP_NOTE, we need to use the legacy template
  if (llmOutputType === DbEnums.LlmOutputType.SOAP_NOTE) {
    outputTemplate = LegacySOAPOutputTemplate;
  } else if (appointmentMode === DbEnums.AppointmentMode.MANUAL_CHARTING) {
    // If we started the appointment in manual charting mode, we need to use the
    // output template from `appointment_human_outputs`
    const outputTemplateRaw = humanOutput?.output_template?.template;
    if (outputTemplateRaw) {
      outputTemplate = dynamicArtifactTemplateSchema.parse(outputTemplateRaw);
    }
  } else if (appointmentMode === DbEnums.AppointmentMode.AUDIO_COPILOT) {
    // If this is an audio co-pilot appointment, we need to use the output template
    // from the LLM output metadata
    const outputTemplateRaw = llmOutputMetadata?.output_variant?.template;
    if (outputTemplateRaw) {
      outputTemplate = dynamicArtifactTemplateSchema.parse(outputTemplateRaw);
    }
  }

  if (!outputTemplate) {
    return {
      llmSummary: null,
      humanSummary: null,
      outputTemplate,
    };
  }

  const hydratedLlmSummary = llmOutputContent
    ? hydrateDynamicArtifactTemplate(outputTemplate, llmOutputContent ?? {})
    : null;

  const hydratedHumanSummary = humanOutputContent
    ? hydrateDynamicArtifactTemplate(outputTemplate, humanOutputContent ?? {})
    : null;

  return {
    llmSummary: hydratedLlmSummary
      ? {
          type: 'DYNAMIC',
          blocks: hydratedLlmSummary.blocks,
        }
      : null,
    humanSummary: hydratedHumanSummary
      ? {
          type: 'DYNAMIC',
          blocks: hydratedHumanSummary.blocks,
        }
      : null,
    outputTemplate,
  };
};
