import { Label } from '@radix-ui/react-context-menu';
import noop from 'lodash/noop';
import React, { useState } from 'react';

import { useCompleteFragment } from '@eluve/apollo-client';
import { Box, HStack, VStack } from '@eluve/components';
import {
  DynamicArtifactTemplate,
  hydrateDynamicArtifactTemplate,
} from '@eluve/llm-outputs';
import {
  DynamicArtifactRenderer,
  SummarySectionMarkdown,
} from '@eluve/smart-blocks';
import { Translator } from '@eluve/translation';

import { useAppointmentParams } from '../useAppointmentParams';

import { draftContentFragment } from './operations';
import { useSummary } from './useSummary';

export const DraftContent: React.FC = () => {
  const { appointmentId } = useAppointmentParams();

  const appointment = useCompleteFragment({
    fragment: draftContentFragment,
    key: { id: appointmentId },
  });
  const [translatedContent, setTranslatedContent] = useState<string | null>(
    null,
  );

  const { humanSummary, outputTemplate } = useSummary({
    appointmentId,
  });

  if (!humanSummary) {
    return (
      <VStack>
        <p>No human summary available for this appointment.</p>
      </VStack>
    );
  }

  let translatedHydratedHumanOutput: DynamicArtifactTemplate | undefined =
    undefined;
  const humanSoapNote = appointment?.humanOutputs?.[0];
  const humanSoapNoteContent = humanSoapNote?.output?.content;

  const toTranslate = {
    humanSoapNoteContent,
    additionalNotes: appointment?.doctor_interaction?.additionalNotes,
  };

  const parsedTranslatedContent: typeof toTranslate = translatedContent
    ? JSON.parse(translatedContent)
    : null;

  const translatedHumanOutputContent =
    parsedTranslatedContent?.humanSoapNoteContent;
  const translatedAdditionalNotes = parsedTranslatedContent?.additionalNotes;

  if (translatedContent && outputTemplate) {
    translatedHydratedHumanOutput = hydrateDynamicArtifactTemplate(
      outputTemplate,
      translatedHumanOutputContent ?? {},
    );
  }

  return (
    <VStack>
      <Box className="w-1/6">
        <Label>Need translation?</Label>
        <Translator
          originalContent={JSON.stringify(toTranslate)}
          setTranslatedContent={setTranslatedContent}
        />
      </Box>
      <HStack align="start">
        <VStack>
          <Label>Doctor Draft</Label>

          <DynamicArtifactRenderer
            isReadonly={true}
            blocks={humanSummary.blocks}
          />

          <Box>
            {appointment?.doctor_interaction?.additionalNotes && (
              <SummarySectionMarkdown
                appointmentId={appointmentId}
                key="AdditionalNotes"
                summaryKey={`AdditionalNotes` as any}
                sectionTitle="Additional Notes"
                content={appointment?.doctor_interaction?.additionalNotes}
                disabled={true}
                handleContentChange={noop}
              />
            )}
          </Box>
        </VStack>
        {translatedHydratedHumanOutput && (
          <VStack>
            <Label>Doctor Draft (translated)</Label>

            <DynamicArtifactRenderer
              isReadonly={true}
              blocks={translatedHydratedHumanOutput.blocks}
            />

            <Box>
              {translatedAdditionalNotes && (
                <SummarySectionMarkdown
                  className="w-full"
                  appointmentId={appointmentId}
                  key={'AdditionalNotes' + translatedAdditionalNotes}
                  summaryKey={`AdditionalNotes` as any}
                  sectionTitle="Additional Notes"
                  content={translatedAdditionalNotes}
                  disabled={true}
                  handleContentChange={noop}
                />
              )}
            </Box>
          </VStack>
        )}
      </HStack>
    </VStack>
  );
};
