import React from 'react';

import { VStack } from '@eluve/components';
import { DynamicArtifactRenderer } from '@eluve/smart-blocks';

import { useAppointmentParams } from '../useAppointmentParams';

import { useSummary } from './useSummary';

export const SummaryContent: React.FC = () => {
  const { appointmentId } = useAppointmentParams();

  const { llmSummary } = useSummary({
    appointmentId,
  });

  if (!llmSummary) {
    return (
      <VStack>
        <p>No LLM summary available for this appointment.</p>
      </VStack>
    );
  }

  return (
    <VStack>
      <DynamicArtifactRenderer isReadonly={true} blocks={llmSummary.blocks} />
    </VStack>
  );
};
