import { useSuspenseQuery } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router-dom';

import {
  latestHumanSOAPNoteFragment,
  useSummary,
} from '@eluve/frontend-appointment-hooks';
import { graphql } from '@eluve/graphql.tada';

import { AppointmentPdf } from './AppointmentPdf';

export const getCurrentSummary = graphql(
  `
    query getCurrentSummary($tenantId: uuid!, $appointmentId: uuid!) {
      appointmentsByPk(tenantId: $tenantId, id: $appointmentId) {
        __typename
        id
        ...LatestHumanSOAPNote
        mode
      }
    }
  `,
  [latestHumanSOAPNoteFragment],
);

export const AppointmentPdfPage: React.FC = () => {
  const { tenantId, appointmentId } = useParams() as {
    tenantId: string;
    appointmentId: string;
  };

  useSuspenseQuery(getCurrentSummary, {
    variables: { appointmentId, tenantId },
  });

  const { summary } = useSummary();

  if (!summary) {
    return <div>Loading...</div>;
  }

  return (
    <AppointmentPdf
      tenantId={tenantId}
      appointmentId={appointmentId}
      summary={summary}
    />
  );
};
